<template>
  <div>
    <AdvanceTable ref="advanceTable" :url="apis.erpIncomingActualPayable" :height="window.height - 310" tableSize="sm" :columns="columns" :selectable="true" @row-selected="onRowSelected" v-loading="isLoading">
      <template #title>
          {{ $t("API Actual Payable") }}
      </template>
      <template #button>
        <b-button variant="secondary" size="sm" class="mr-50" @click="openModalForUpload">
          <feather-icon icon="UploadIcon"/>
          <span class="align-middle">{{ $t("Import") }}</span>
        </b-button>

        <b-button variant="secondary" size="sm" class="mr-50" @click.exact="exportExcel('EXCEL')">
          <feather-icon icon="DownloadIcon"/>
          <span class="align-middle">{{ $t("Download") }}</span>
        </b-button>

        <b-button variant="secondary" size="sm" class="mr-50" @click.exact="showMappings()">
          <feather-icon icon="GitMergeIcon"/>
          <span class="align-middle">{{ $t("AP Mapping") }}</span>
        </b-button>
      </template>
      <template #cell(unitPrice)="row">
        {{ row.item.unitPrice }} {{ row.item.currency }}
      </template>
      <template #cell(amount)="row">
        {{ row.item.amount }} {{ row.item.currency }}
      </template>
      <template #cell(accrualNumber)="row">
        {{ row.item.accrualAssocs ? row.item.accrualAssocs.map((item) => item.accrual.accrualNumber).join(', ') : '' }}
        <el-link @click.stop="reviseMapping(row.item)" v-if="row.item.accrualAssocs.length > 0">
          <i class="el-icon-link"></i>
          Revise
        </el-link>
      </template>
      <template #cell(addTime)="row">
        {{ formatWithCurrentTimezone(row.item.addTime) }}
      </template>
      <template #cell(selection)="row">
        <input type="checkbox" :checked="row.rowSelected" style="pointer-events: none" :disabled="row.item.accrualAssocs.length > 0"/>
      </template>
      <template #head(selection)="row">
        <input type="checkbox" style="margin-top: 40px" ref="selectAll" @click="onSelectAllSelected">
      </template>

      <template #cell(actions)="row">
        <el-link @click.stop="reviseMapping(row.item)" v-if="row.item.accrualAssocs.length > 0">
          <i class="el-icon-link"></i>
          Revise Mapping
        </el-link>
      </template>
    </AdvanceTable>

    <UploadModal ref="uploadView">
      <template v-slot:tip="scope">
        Only accepts xlsx files with size less than {{ $refs.uploadView ? $refs.uploadView.getMaxSizeForDisplay() : '' }}<br/>
        <a href="https://prod-kec-dashboard.s3.ap-east-1.amazonaws.com/public/Actual-Payable-Template.xlsx" target="_blank" @click.stop>Download Template</a>
      </template>
    </UploadModal>

    <ExcelResultModal ref="excelResult"/>

    <el-dialog
      class="compact"
      :title="$t('Accrual Link -  ' + selected.length + ' selected')"
      :visible.sync="isShowLink"
      width="90%"
      v-loading="isLoading"
    >
      <table class="table">
        <thead>
        <tr>
          <th>Account Code</th>
          <th>Billing Number</th>
          <th>Total Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="payable in selected">
          <td>{{ payable.accountCode }}</td>
          <td>{{ payable.billingNumber }}</td>
          <td>{{ toFixed(payable.amount, 2) }} {{ payable.currency }}</td>
        </tr>
        </tbody>
      </table>

      <div>
        <el-select v-model="linkAccrualId" value-key="id" clearable>
          <el-option v-for="item in companyAccruals" :key="item.id" :label="item.accrualNumber" :value="item.id" v-if="item.deleteTime === null" :disabled="(item.chargeCodeEntity && item.chargeCodeEntity.type === 'AR') || item.confirmTime !== null">
            {{ item.accrualNumber }} / Type: {{ item.chargeCodeEntity && item.chargeCodeEntity.type ? item.chargeCodeEntity.type : 'Unspecified' }} / Charge Code: {{ item.chargeCode }} / Warehouse Code: {{ item.warehouseCode }}
          </el-option>
        </el-select>

        <el-button type="primary" class="ml-1" @click="submitLink()">Link Accrual</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getErpProfile } from "@/utils/account-localstorage";
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import { BButton, BModal } from "bootstrap-vue";
import apis from "@/configs/apis";
import { formatWithCurrentTimezone } from "./utils/time";
import axios from "axios";
import UploadModal from "@/views/erp/components/UploadModal.vue";
import ExcelResultModal from "@/views/erp/components/ExcelResultModal.vue";
import { MAX_PAGE_SIZE } from "@/views/erp/utils/system";
import Prompt from "@/views/erp/mixins/Prompt";
import { toFixed } from "@/views/erp/utils/string";

export default {
  mixins: [Prompt],
  components: {
    ExcelResultModal, UploadModal,
    BButton, BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "selection", width: "30", align: "left"},
        { key: "customerName", modelName: "customerName", label: "Customer Name", width: "100", filtertype: "input", sortable: true },
        { key: "vendorName", modelName: "vendorName", label: "Vendor Name", width: "100", filtertype: "input", sortable: true },
        { key: "systemCode", modelName: "systemCode", label: "System Code", width: "100", filtertype: "input", sortable: true },
        { key: "accountCode", modelName: "accountCode", label: "Account Code", width: "100", filtertype: "input", sortable: true },
        { key: "billingNumber", modelName: "billingNumber", label: "Billing Number", width: "100", filtertype: "input", sortable: true },
        { key: "billingPeriod", modelName: "billingPeriod", label: "Billing Period", width: "80", filtertype: "fiscal", sortable: true },
        { key: "groupingId", modelName: "groupingId", label: "Grouping ID", width: "80", filtertype: "input", sortable: true },
        { key: "chargeCode", modelName: "chargeCode", label: "Charge Code", width: "100", filtertype: "input", sortable: true },
        { key: "warehouseCode", modelName: "warehouseCode", label: "W/H Code", width: "80", filtertype: "input", sortable: true },
        { key: "countryCode", modelName: "countryCode", label: "Country Code", width: "80", filtertype: "input", sortable: true },
        { key: "unitPrice", modelName: "unitPrice", label: "Unit Price", width: "50",  sortable: true },
        { key: "qty", modelName: "qty", label: "Qty", width: "50",  sortable: true },
        { key: "amount", modelName: "amount", label: "Total Amount", width: "100", sortable: true },
        { key: "description", modelName: "description", label: "Description", width: "100", filtertype: "input", sortable: true },
        { key: "status", modelName: "status", label: "Status", width: "80", filtertype: "select", options: { 'settled': 'Settled', 'billable': 'Billable'}, sortable: true, align: "center" },
        { key: "accrualNumber", modelName: "accrualNumber", label: "Accrual Number", width: "120" },
        { key: "addTime", modelName: "addTime", label: "Add Time", width: "120" },
        // { key: "actions", modelName: "actions", label: "Actions", sortable: true },
      ],
      selected: [],
      linkAccrualId: null,
      companyAccruals: [],
      summary: {},
      accrualLinks: [],
      accrualLink: {accrual: {}, incomingActualPayable: {}},
      accrualLinkDisplay: {accrual: {}, incomingActualPayable: {}},
      isShowLink: false,
      isLoading: false,
    };
  },
  computed: {
    apis() {
      return apis
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    toFixed,
    formatWithCurrentTimezone,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    show(item) {
      this.$router.push({
        name: 'erp-company-profile',
        params: { id: item.organizationProfileId },
        hash: '#invoice'
      })
    },

    onRowSelected(items) {
      let accountCodes = [... new Set(items.map((item) => item.accountCode))];

      if (accountCodes.length > 1) {
        let newIds = items.map((item) => item.id);
        let oldIds = this.selected.map((item) => item.id);
        let diffIds = newIds.filter(n => !oldIds.includes(n));

        for (let rowIndex = 0; rowIndex < this.$refs.advanceTable.rows.length; rowIndex += 1) {
          if (this.$refs.advanceTable.rows[rowIndex]['id'] === diffIds[0]) this.$refs.advanceTable.$refs.advanceTable.unselectRow(rowIndex);
        }

        return false;
      }

      // Unselect associated accruals
      for (let rowIndex = 0; rowIndex < this.$refs.advanceTable.rows.length; rowIndex += 1) {
        if (this.$refs.advanceTable.rows[rowIndex].accrualAssocs.length > 0) this.$refs.advanceTable.$refs.advanceTable.unselectRow(rowIndex);
      }

      this.selected = items;

      this.updateSelectAllState();
    },

    onSelectAllSelected() {
      if (this.$refs.selectAll.checked) {
        this.$refs.advanceTable.$refs.advanceTable.selectAllRows();
      } else {
        this.$refs.advanceTable.$refs.advanceTable.clearSelected();
      }
    },

    updateSelectAllState() {
      if (this.selected.length === 0) {
        this.$refs.selectAll.checked = false;
        this.$refs.selectAll.indeterminate = false;
      } else if (this.selected.length === this.$refs.advanceTable.rows.length) {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = false;
      } else {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = true;
      }
    },

    openModalForUpload() {
      this.$refs.uploadView.showUpload('Upload Actual Payable', 'ACTUAL_PAYABLE', {maxSize: 10485760, allowedExtension: 'xlsx'}, (form) => {
        this.isLoading = true;

        axios.post(
          apis.erpProcessIncomingActualPayable,
          form
        ).then((response) => {
          this.$refs.advanceTable.loadList();
          this.$refs.uploadView.hideUpload();
          this.$refs.excelResult.showResult(response.data.data);
        }).catch(e => {
          console.log(e);
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },

    exportExcel(exportType) {
      this.isLoading = true;

      let urlParams = '';
      let params = this.$refs.advanceTable.filter
      params.pageSize = MAX_PAGE_SIZE;

      for (const k in params){
        if (typeof params[k] != 'undefined') urlParams += k + "=" + params[k] + "&"
      }

      axios.get(apis.erpIncomingActualPayable.substring(1) + "/export?exportType=" + exportType + '&' + urlParams, {
        responseType: 'blob'
      })
        .then((response) => {
          const anchorElement = document.createElement('a');
          document.body.appendChild(anchorElement);
          anchorElement.style.display = 'none';

          const url = window.URL.createObjectURL(response.data);

          let filename;
          if (exportType === 'EXCEL') {
            filename = 'Actual-Payable.xlsx';
          }

          anchorElement.href = url;
          anchorElement.download = filename;
          anchorElement.click();

          window.URL.revokeObjectURL(url);
        }).catch((e) => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    loadCompanyAccrual(accountCode) {
      return axios.get(apis.erpAccruals, {params: {accountCode: accountCode, pageSize: MAX_PAGE_SIZE}})
        .then(response => {
          this.companyAccruals = response.data.data.data;
        })
    },

    showMappings() {
      if (this.selected.length === 0) {
        this.promptError('Please select at least one row');

        return;
      }

      this.loadCompanyAccrual(this.selected[0].accountCode);

      this.isShowLink = true;
    },

    submitLink() {
      if (this.linkAccrualId === null) {
        this.promptError('Please select an accrual');

        return;
      }

      this.isLoading = true;

      axios.post(apis.erpIncomingActualPayable + '/link-accruals', {
        ids: this.selected.map((item) => item.id),
        accrualId: this.linkAccrualId
      }).then((response) => {
        this.$refs.advanceTable.loadList();
        this.isShowLink = false;
      }).catch(e => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    reviseMapping(item) {
      const accrualLink = item.accrualAssocs[0];
      console.log(accrualLink);
      axios.delete(
        apis.erpIncomingActualPayable + '/' + accrualLink.incomingActualPayable.id + '/accruals/' + accrualLink.accrual.id,
        {accrualId: this.linkAccrualId}
      ).then((response) => {
        this.promptInfo('Accrual unlinked successfully')
        this.$refs.advanceTable.loadList();
      }).catch(e => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

