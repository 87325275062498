<template>
  <div class="card p-2" v-loading="loading">
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-chat-line-square"></i>{{$t('Scope of Services')}} * </template>
          <validation-provider #default="{ errors }" name="Title" rules="required">
            <el-input v-model="form.title" :placeholder="$t('Please Enter Scope of Services')" style="width: 100%"/>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-money"></i>{{$t('Currency')}} * </template>
          <validation-provider #default="{ errors }" name="Currency" rules="required">
            <el-select v-model="form.currency" filterable :clearable="false" :placeholder="$t('Select')" @change="onCurrencyChange" style="width: 100%">
              <el-option v-for="item in currencyList" :key="item.value.code" :label="item.value.code" :value="item.value.code"> </el-option>
            </el-select>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-office-building"></i>{{$t('Pipeline')}} * </template>
          <validation-provider #default="{ errors }" name="Pipeline" rules="required">
            <el-select v-model="form.pipeline_id" filterable :placeholder="$t('Please Select Pipeline')" @change="onPipelineChange()" style="width: 100%">
              <el-option v-for="item in pipelineList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-user"></i>{{$t('Stage')}}  * </template>
          <validation-provider #default="{ errors }" name="Stage" rules="required">
            <el-select v-model="form.stage_id" filterable :placeholder="$t('Please Select Stage')" style="width: 100%" :disabled="!dealSelected">
              <el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-office-building"></i>{{$t('Organisation')}}  * </template>
          <validation-provider #default="{ errors }" name="Organisation" rules="required">
            <el-select v-model="form.organization_id" filterable :placeholder="$t('Please Select Organisation')" style="width: 100%" @change="onOrganizationChange">
              <el-option v-for="item in organizationList" :key="item.id" :label="item.name + (item.status === 'PENDING' ? ' (' + item.status + ')' : '')" :value="item.id"></el-option>
            </el-select>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-user"></i>{{$t('Owner')}}  * </template>
          <validation-provider #default="{ errors }" name="Owner" rules="required">
            <el-select v-model="form.user_id" filterable :placeholder="$t('Please Select Owner')" style="width: 100%" :disabled="isOwnerDisabled()">
              <el-option v-for="item in pipelineUserList" :key="item.id" :label="item.user.name" :value="item.user.id"></el-option>
            </el-select>
          </validation-provider>
          <span v-if="organizationOwner">Organisation owned by {{ userList.find(u => u.id === organizationOwner).name }} on this pipeline.</span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-price-tag"></i>{{$t('Tags')}}  * </template>
          <validation-provider #default="{ errors }" name="Tags" rules="required">
          <el-select v-model="form.tags" filterable :placeholder="$t('Please Select Tags')" multiple :multiple-limit="1" style="width: 100%">
            <el-option v-for="tag in tagList" :key="tag.value.id" :label="tag.value.locale && tag.value.locale[$i18n.locale] ? tag.value.locale[$i18n.locale] : tag.value.name" :value="tag.id"></el-option>
          </el-select>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <template slot="label"><i class="el-icon-user"></i>{{$t('Contact Person')}} * </template>
          <validation-provider #default="{ errors }" name="Contact" rules="required">
            <el-select v-model="form.contact_id" filterable :placeholder="$t('Please Select Contact Person')" style="width: 100%" @change="loadContactDetail()">
              <el-option v-for="item in filteredContactList" :key="item.id" :label="item.summary" :value="item.id"></el-option>
            </el-select>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            {{$t('Contact Phone')}}
          </template>
          <validation-provider #default="{ errors }" name="Phone" rules="required">
            <el-input required v-model="form.phone" :validate-event="true" disabled></el-input>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-message"></i>{{$t('Contact Email')}} </template>
          <validation-provider #default="{ errors }" name="Contact Email" rules="required">
            <el-input required v-model="form.email" :validate-event="true" disabled></el-input>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <template slot="label"> <i class="el-icon-user"></i> {{$t('Internal Referral')}} * </template>
          <validation-provider #default="{ errors }" name="InternalReferral" rules="required">
            <el-radio v-model="form.is_internal_referral" :label="false" @change="onInternalReferralChange()">{{$t('No')}}</el-radio>
            <el-radio v-model="form.is_internal_referral" :label="true" @change="onInternalReferralChange()">{{$t('Yes')}}</el-radio>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="3" v-if="form.is_internal_referral">
        <b-form-group>
          <template slot="label"> <i class="el-icon-user"></i> {{$t('Internal Referral Type')}} * </template>
          <validation-provider #default="{ errors }" name="InternalReferralType" rules="required">
            <el-select v-model="form.internal_referral_type" filterable placeholder="Please Select Type" style="width: 100%">
              <el-option v-for="item in referralUserList" :key="item.value.id" :label="item.value.name" :value="item.value.name"></el-option>
            </el-select>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="3" v-if="form.is_internal_referral">
        <b-form-group>
          <template slot="label"> <i class="el-icon-user"></i> {{$t('Internal Referral User')}} * </template>
          <validation-provider #default="{ errors }" name="InternalReferralUser" rules="required">
            <el-input v-model="form.internal_referral_user" style="width: 100%"/>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <hr/>

    <table>
      <tr style="font-size: 12px;">
        <td>{{$t('Product Code')}}</td>
        <td>{{$t('Origin')}}</td>
        <td>{{$t('Destination')}}</td>
        <td>{{$t('Type')}}</td>
        <td v-if="enableProductCurrency">{{$t('Currency')}}</td>
        <td>{{$t('Unit Rate')}}</td>
        <td>{{$t('No. of Parcel/Month')}}</td>
        <td>{{$t('Amount')}}</td>
        <td></td>
      </tr>
      <tr v-for="(product, k) in form.products" :key="k">
        <td>
          <el-input class="" type="text" v-model="product.code" style="width: 240px;" :disabled="!product.is_temporary"/>
        </td>
        <td>
          <el-input class="" type="text" v-model="product.origin" style="width: 100px;" :disabled="!product.is_temporary"/>
        </td>
        <td>
          <el-input class="" type="text" v-model="product.destination" style="width: 100px;" :disabled="!product.is_temporary"/>
        </td>
        <td>
          <el-select v-model="product.product_type" placeholder="" :disabled="!product.is_temporary">
            <el-option v-for="item in productTypeList" :key="item.value.name" :label="item.value.name" :value="item.value.name"> </el-option>
          </el-select>
        </td>
        <td v-if="enableProductCurrency">
          <el-select v-model="product.currency" placeholder="" @change="calculateLineTotal(product)">
            <el-option v-for="item in currencyList" :key="item.value.code" :label="item.value.code" :value="item.value.code"> </el-option>
          </el-select>
        </td>
        <td>
          <el-input class="text-right" type="number" min="0" step=".01" v-model="product.price" @change="calculateLineTotal(product)" />
        </td>
        <td>
          <el-input class="text-right" type="number" min="0" step="1" v-model="product.qty" @change="calculateLineTotal(product)" />
        </td>
        <td>
          <el-input readonly class="text-right" type="number" min="0" step=".01" v-model="product.line_total" :disabled="!product.is_temporary" />
        </td>
        <td scope="row" class="trashIconContainer">
          <feather-icon icon="Trash2Icon" size="16" class="text-muted" @click="deleteRow(k, product)" style="cursor: pointer" />
        </td>
      </tr>
      <tfoot>
        <tr v-if="form.currency">
          <td v-if="enableProductCurrency"></td>
          <td colspan="6" style="text-align: right">{{$t('Monthly')}} {{ form.currency }}&nbsp;</td>
          <td style="text-align: right"><el-input disabled class="text-right" type="number" min="0" step=".01" v-model="form.value" /></td>
        </tr>
        <tr v-if="form.currency">
          <td v-if="enableProductCurrency"></td>
          <td colspan="6" style="text-align: right">{{$t('Annual')}} {{ form.currency }}&nbsp;</td>
          <td style="text-align: right"><el-input disabled class="text-right" type="number" min="0" step=".01" v-model="form.value * 12" /></td>
        </tr>
      </tfoot>
    </table>
    <div>
      <el-button style="margin-right: 10px; background: #fd7e14; border: #fd7e14; width: 200px" type="primary" icon="el-icon-plus" @click="addPermanentProductDialog()">{{$t('Permanent Product')}}</el-button>
      <el-button style="margin-right: 10px; background: #fd7e14; border: #fd7e14; width: 200px" type="primary" icon="el-icon-plus" @click="addTempProduct()">{{$t('Temporary Product')}}</el-button>
    </div>
    <div>
      <el-button type="primary" size="small" style="float: right; margin-top: 20px; width: 150px" @click="save">{{$t('Save')}}</el-button>
    </div>

    <b-modal id="modal-add-permanent-product" hide-footer size="lg" v-model="isShow" :title="$t('Add Permanent Product')">
      <div class="card p-2" v-loading="loading">
        <el-form>
            <b-form-group>
              <template slot="label"> <i class="el-icon-search"></i>{{$t('Search Product')}} </template>
              <b-row>
                <b-col>
                  <el-input v-model="searchOrigin" :placeholder="$t('Origin')" @change="loadProduct"></el-input>
                </b-col>
                <b-col>
                  <el-input v-model="searchDestination" :placeholder="$t('Destination')" @change="loadProduct"></el-input>
                </b-col>
                <b-col>
                  <el-select v-model="searchSource" :placeholder="$t('Source')" @change="loadProduct">
                    <el-option v-for="item in sources" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
            <template slot="label"> <i class="el-icon-plus"></i>{{$t('Select Product')}} * </template>
            <validation-provider #default="{ errors }" name="Permanent Product" rules="required">
              <el-select v-model="permanentProductId" filterable :placeholder="$t('Please Select Product')" style="width: 100%">
                <el-option v-for="item in filterProductList.filter(p => !p.is_temporary)" :key="item.id" :value="item.id" :label="item.product_type + ' / ' + item.description + ' / ' + item.code">
                  <span style="">{{ item.product_type }} / {{ item.description }} / {{ item.code }}</span>
                  <span style="float: right; color: #8492a6;">
                    {{ item.origin_description }} &gt; {{ item.destination_description }}
                  </span>
                </el-option>
              </el-select>
            </validation-provider>
          </b-form-group>
        </el-form>
        <div>
          <el-button
            style="float: right; margin: 20px 0; width: 200px; background: #66b1ff; border: #66b1ff; margin-left: 20px;"
            type="primary" @click="addPermanentProduct()" icon="el-icon-check">{{$t('Add Products')}}
          </el-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import { BFormSelect } from "bootstrap-vue";
import { isDealSuperEditable } from "@/auth/crm";
import { getCrmProfile } from "@/utils/account-localstorage";

export default {
  components: {
    BFormSelect,
  },
  props: {
    pipelineList: Array,
    stageList: Array,
    userList: Array,
    pipelineUserList: Array,
    referralUserList: Array,
    contactList: Array,
    organizationList: Array,
    tagList: Array,
    productList: Array,
    productTypeList: Array,
    currencyList: Array,

    pipelineSelected: Object,
    dealSelected: Object,
    isPreview: Boolean,
  },
  data() {
    return {
      form: {
        title: "",
        pipeline_id: null,
        stage_id: null,
        organization_id: null,
        user_id: null,
        contact_id: null,
        phone: null,
        email: null,
        currency: null,
        is_internal_referral: false,
        internal_referral_type: null,
        internal_referral_user: null,
        tags: [],
        products: [],
        value: 0
      },

      organizationOwner: null,

      sources: [
        {id: '', name: 'All'},
        {id: 'CRM', name: 'CRM'},
        {id: 'K_ENGINE', name: 'K_ENGINE'},
        {id: 'K_PARCEL', name: 'K_PARCEL'},
      ],

      searchOrigin: '',
      searchDestination: '',
      searchSource: '',
      filterProductList: this.productList,
      organizationOwnershipList: [],
      filteredContactList: [],

      isShow: false,
      loading: false,

      enableProductCurrency: false,

      permanentProductId: null,
    };
  },
  mounted() {
    this.form.pipeline_id = this.pipelineSelected.id;

    this.loadStage();
    this.loadOrganizationOwnership();

    if (this.dealSelected) {
      let myForm = Object.assign({}, this.dealSelected);

      let tagIds = [];
      myForm.tags.forEach(tag => {
        tagIds.push(tag.id);
      });
      myForm.tags = tagIds;

      this.form = myForm;

      this.loadContactDetail();
      this.onCurrencyChange();
      this.calculateTotal();
      this.onOrganizationChange();
    } else {
      this.form.user_id = getCrmProfile().id;
    }
  },
  methods: {
    isDealSuperEditable,
    loadContactDetail() {
      this.contactList.forEach(contact => {
        if (contact.id === this.form.contact_id) {
          this.form.phone = contact.phone;
          this.form.email = contact.email;
        }
      });
    },

    save() {
      // Do validations here
      if (!this.form.title) {
        this.$emit('error', 'Scope of Services must be filled.');
        return;
      }

      if (!this.form.currency) {
        this.$emit('error', 'Currency must be selected.');
        return;
      }

      if (!this.form.pipeline_id) {
        this.$emit('error', 'Pipeline must be selected.');
        return;
      }

      if (!this.form.stage_id) {
        this.$emit('error', 'Stage must be selected.');
        return;
      }

      if (!this.form.user_id) {
        this.$emit('error', 'Owner must be selected.');
        return;
      }

      if (this.form.tags.length === 0) {
        this.$emit('error', 'Tags must be selected.');
        return;
      }

      if (!this.form.organization_id) {
        this.$emit('error', 'Organisation must be selected.');
        return;
      }

      if (!this.form.contact_id) {
        this.$emit('error', 'Contact Person must be selected.');
        return;
      }

      if (this.form.is_internal_referral === null) {
        this.$emit('error', 'Internal Referral must be selected.');
        return;
      }

      if (this.form.is_internal_referral && !this.form.internal_referral_user && !this.form.internal_referral_type) {
        this.$emit('error', 'Internal Referral User must be selected.');
        return;
      }

      if (this.dealSelected) {
        this.updateDeal();
      } else {
        this.createDeal();
      }
    },

    async loadStage() {
      const responseStage = await fetcher(apis.crmGetPipeline + "/" + this.form.pipeline_id + "/stage", FetchMethod.GET);
      this.stageList = responseStage.data;

      if (!this.form.stage_id) this.form.stage_id = this.stageList[0].id;
    },

    async createDeal() {
      this.loading = true;
      let promise = fetcher(apis.crmAddDeals, FetchMethod.POST, this.form);
      promise.then(() => {
        this.$bvModal.hide('modal-create');
        this.$emit('created', 'Deal Created')
      })
      promise.catch((e) => {
        this.$emit('error', e.message + (e.detail ? ': ' + e.detail : ''))
      });
      promise.finally(() => {
        this.loading = false;
      });
    },

    async loadProduct() {
      this.loading = true;
      const response = await fetcher(
        apis.crmGetProducts,
        FetchMethod.GET,
        {'originCountries.countryCode': this.searchOrigin, 'destinationCountries.countryCode': this.searchDestination, 'source': this.searchSource}
      )
        .catch(e => {
          this.$emit('error', e.message + (e.detail ? ': ' + e.detail : ''))
        })
        .finally(() => {
          this.loading = false;
        });

      this.filterProductList = response.data;
      this.permanentProductId = null;
    },

    async loadOrganizationOwnership() {
      const response = await fetcher(
        apis.crmGetOrganizationOfPipeline + '/' + this.form.pipeline_id + "/organization",
        FetchMethod.GET,
        {}
      ).catch(e => {})

      this.organizationOwnershipList = response.data;
    },

    async updateDeal() {
      this.loading = true;
      let promise = fetcher(apis.crmUpdateDeal + '/' + this.form.id, FetchMethod.PUT, this.form);
      promise.then(() => {
        this.$emit('updated', 'Deal Updated');
        this.$bvModal.hide('modal-edit');
      })
      promise.catch((e) => {
        this.$emit('error', e.message + (e.detail ? ': ' + e.detail : ''))
      });
      promise.finally(() => {
        this.loading = false;
      });
    },

    addPermanentProductDialog: function () {
      this.isShow = true;
    },

    addPermanentProduct: function () {
      if (!this.permanentProductId) return;

      let product = this.filterProductList.find(product => product.id === this.permanentProductId);
      product.currency = this.form.currency;
      this.form.products.push(product);

      this.$bvModal.hide('modal-add-permanent-product');
    },

    addTempProduct: function () {
      this.form.products.push({
        code: "",
        origin: "",
        destination: "",
        product_type: "",
        price: "",
        qty: "",
        line_total: "",
        currency: this.form.currency,
        is_temporary: true,
      });
    },

    async onPipelineChange() {
      await this.loadStage();
      await this.loadOrganizationOwnership();
      await this.onOrganizationChange();

      this.form.stage_id = null;
    },

    async onOrganizationChange() {
      this.organizationOwner = null;

      // Check ownership and assign automatically
      this.organizationOwnershipList.forEach(o => {
        if (o.organization.id === this.form.organization_id) {
          this.form.user_id = o.user.id;
          this.organizationOwner = o.user.id;
        }
      });

      // Fallback to user if no owner on create
      if (!this.organizationOwner && !this.dealSelected) {
          this.form.user_id = getCrmProfile().id;
      }

      this.filteredContactList = this.contactList.filter(c => c.organization_id === this.form.organization_id);
    },

    onInternalReferralChange() {
      if (!this.form.is_internal_referral) {
        this.form.internal_referral_user = null;
        this.form.internal_referral_type = null;
      }
    },
    onCurrencyChange() {
      if (!this.enableProductCurrency) {
        this.form.products.forEach(product => {
          product.currency = this.form.currency;
        });
      }

      this.calculateTotal();
    },
    calculateLineTotal(product) {
      product.line_total = product.price * product.qty;

      this.calculateTotal();
    },
    calculateTotal() {
      let total = this.form.products.reduce((sum, product) => {
        if (product.currency) {
          // Convert to USD
          let productCurrency = this.currencyList.find(c => c.value.code === product.currency);
          return sum + product.line_total * productCurrency.value.rate;
        }
      }, 0);

      if (this.form.currency) {
        // Convert to Deal currency
        let dealCurrency = this.currencyList.find(c => c.value.code === this.form.currency);
        this.form.value = total / dealCurrency.value.rate;
      } else {
        this.form.value = NaN;
      }

      this.$forceUpdate();
    },
    deleteRow(index, product) {
      let idx = this.form.products.indexOf(product);
      if (idx > -1) {
        this.form.products.splice(idx, 1);
      }
      this.calculateTotal();
    },
    isOwnerDisabled() {
      if (!this.dealSelected) return true; // No select on create
      return (this.organizationOwner || this.dealSelected) && !isDealSuperEditable(this.pipelineSelected, this.dealSelected);
    },
    saveDetail() {},
  },
};
</script>

<style>
.text-right input {text-align: right}
</style>
