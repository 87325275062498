<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="/crm/organization" :height="window.height - 310" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" @clear="clearData"  @edit="openModalForEdit">
        <template #title>
            {{ $t("Organisations") }}
        </template>

        <template #cell(status)="row">
          {{ row.value }}
          <div v-if="row.value === 'PENDING' && getCrmProfile().role_id === 1">
            <el-link icon="el-icon-document-copy" @click="mergeOrganizationShow(row)" v-if="getCrmProfile().role_id === 1">Merge / Verify</el-link>
          </div>
        </template>

          <template #cell(owner)="row">
              <el-link icon="el-icon-view" @click="ownerShow(row)">View</el-link>
          </template>

        <template #button>
            <b-button variant="secondary" class="mr-1" @click="openModalForKeyword" v-if="isSuperAdmin()">
              <feather-icon icon="CrosshairIcon" class="mr-50" />
              <span class="align-middle">{{ $t("Ignored Keywords") }}</span>
            </b-button>

            <b-button variant="info" class="mr-1" @click="openModalForCreate" v-if="">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">{{ $t("Organisation") }}</span>
            </b-button>
        </template>
        </AdvanceTable>
    </section>
  <b-modal
    id="api-log"
    centered
    :title="$t(title)"
    size="lg"
    v-model="isShow"
    hide-footer
  >
   <b-card no-body v-loading="isLoading">
      <b-card-body>
          <validation-observer ref="rules" v-slot="{ invalid }">
              <b-row >
                  <b-col lg="12" >
                      <b-form-group
                      :label="$t('Name')"
                      label-for=""
                      >
                          <el-input v-model="info.name"
                              :disabled="info.status !== 'PENDING' && action === 'UPDATE'"
                              @change="checkName()"
                          />
                          <div v-if="info.nameWarning" style="font-weight: bold; color: orange; padding: 4px 0;" v-html="nameWarningText"></div>
                      </b-form-group>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col lg="12">
                      <b-form-group
                      :label="$t('Address')"
                      label-for=""
                      >
                          <el-input v-model="info.address"
                              ref="input1"
                          />
                      </b-form-group>
                  </b-col>
                  <b-col lg="12">
                      <b-form-group
                      :label="$t('Country')"
                      label-for=""
                      >
                          <el-select filterable v-model="info.country_code" placeholder="Please Select Country" style="width: 100%">
                            <el-option v-for="item in countryList" :key="item.value" :label="item.text" :value="item.value"></el-option>
                          </el-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="12" v-if="getCrmProfile().role_id === 1 && action === 'UPDATE'">
                      <b-form-group
                        :label="$t('Status')"
                        label-for=""
                      >
                        <el-select v-model="info.status" value-field="id" text-field="name" style="width: 100%">
                          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="12" v-if="getCrmProfile().role_id === 1 && action === 'UPDATE'">
                    <b-form-group
                      :label="$t('Remark')"
                      label-for=""
                    >
                      <b-form-textarea disabled v-model="info.remark" rows="3" max-rows="10"></b-form-textarea>
                    </b-form-group>
                  </b-col>
              </b-row>

          <div>
              <div class="w-100 d-flex justify-content-end">
                  <b-button
                      variant="info"
                      class="btn mr-2"
                      @click="isShow=false;$refs.advanceTable.loadList()"
                  >
                      {{ $t('Cancel') }}
                  </b-button>
                  <b-button
                      variant="primary"
                      class="btn"
                      @click="save"
                      :disabled="invalid"
                  >
                      {{ $t('Save') }}
                  </b-button>
              </div>
          </div>
          </validation-observer>
      </b-card-body>
   </b-card>
  </b-modal>

  <b-modal
    id="ignored-keywords"
    centered
    :title="$t('Ignored Keywords')"
    size="lg"
    v-model="isShowKeyword"
    hide-footer
  >
    <b-card no-body>
      <b-card-body>
        <el-input type="textarea" v-model="ignoredKeywords" rows="10"/>
        <div class="mt-1">Symbols - , . ( ) are always ignored.</div>

        <div class="mt-2">
          <div class="w-100 d-flex justify-content-end">
            <b-button variant="info" class="btn mr-2" @click="isShowKeyword = false;">{{ $t('Cancel') }}</b-button>
            <b-button variant="primary" class="btn" @click="saveIgnoredKeywords()">{{ $t('Save') }}</b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-modal>

  <b-modal id="modal-merge-product" hide-footer size="xl" v-model="isShowMerge" :title="$t('Merge Organisations')">
    <div class="card p-2" v-loading="isLoading">
      <el-form>
        <b-form-group>
          <template slot="label">{{$t('Source Organisation')}}</template>
          <b-row>
            <b-col cols="2"><label>{{$t('ID')}}</label> <el-input v-model="sourceOrganization.id" disabled/></b-col>
            <b-col cols="3"><label>{{$t('Name')}}</label> <el-input v-model="sourceOrganization.name" disabled/></b-col>
            <b-col cols="3"><label>{{$t('Country')}}</label> <el-input v-model="sourceOrganization.country_code" disabled/></b-col>
            <b-col cols="4"><label>{{$t('Address')}}</label> <el-input v-model="sourceOrganization.address" disabled/></b-col>
          </b-row>

          <b-row>
            <b-col cols="12"><label>{{$t('Remark')}}</label> <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10 }" v-model="sourceOrganization.remark" disabled/></b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col cols="12" align="center"><feather-icon icon="ArrowDownIcon" size="36" class="text-muted"/></b-col>
          </b-row>
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group>
              <template slot="label">{{$t('Merge into existing Organisation')}}</template>
              <validation-provider #default="{ errors }" name="Target Organization" rules="required">
                <el-select filterable v-model="targetId" placeholder="Please Select Organization" style="width: 100%" @change="selectTargetOrganization">
                  <el-option v-for="item in organizationList.filter(p => p.status !== 'PENDING')" :key="item.id" :value="item.id" :label="'ID: ' + item.id + ' / Name: ' + item.name + ' / Country: ' + item.country_code + ' / Address: ' + item.address"></el-option>
                </el-select>
                <b-row>
                  <b-col cols="2"><label>{{$t('ID')}}</label> <el-input v-model="targetOrganization.id" disabled/></b-col>
                  <b-col cols="3"><label>{{$t('Name')}}</label> <el-input v-model="targetOrganization.name" disabled/></b-col>
                  <b-col cols="3"><label>{{$t('Country')}}</label> <el-input v-model="targetOrganization.country_code" disabled/></b-col>
                  <b-col cols="4"><label>{{$t('Address')}}</label> <el-input v-model="targetOrganization.address" disabled/></b-col>
                </b-row>
              </validation-provider>
            </b-form-group>

            <el-button
              style="float: right; margin: 20px 0; width: 200px; background: #66b1ff; border: #66b1ff; margin-left: 20px;"
              type="primary" @click="mergeOrganization()" icon="el-icon-check">{{$t('Merge Organisations')}}
            </el-button>
          </b-col>
          <b-col>
            <b-form-group>
              <template slot="label">{{$t('Verify Organisation')}}</template>

              <el-button
                style="float: left; margin: 20px 0; width: 200px; background: #66b1ff; border: #66b1ff; margin-left: 20px; margin-top: 137px;"
                type="success" @click="verifyOrganization()" icon="el-icon-check">{{$t('Verify Organisation')}}
              </el-button>
            </b-form-group>
          </b-col>
        </b-row>
      </el-form>
    </div>
  </b-modal>

  <b-modal id="modal-owner" hide-footer size="lg" v-model="isShowOwner" :title="$t('Show Organisation Owner')">
    <div class="card p-2" v-loading="isLoading">
      <el-form>
        <b-form-group>
          <b-row>
            <b-col cols="2"><label>{{$t('ID')}}</label> <el-input v-model="info.id" disabled/></b-col>
            <b-col cols="3"><label>{{$t('Name')}}</label> <el-input v-model="info.name" disabled/></b-col>
            <b-col cols="3"><label>{{$t('Country')}}</label> <el-input v-model="info.country_code" disabled/></b-col>
            <b-col cols="4"><label>{{$t('Address')}}</label> <el-input v-model="info.address" disabled/></b-col>
          </b-row>
        </b-form-group>

        <hr/>

        <h4>Creator</h4>

        <div>
          Created by {{ info.creator ? info.creator.name : 'Unknown' }} at {{ info.add_time | formatTimestamp }}
        </div>

        <hr/>

        <h4>Owners</h4>

        <table style="width: 100%">
          <tr>
            <th>Pipeline</th>
            <th>Owner</th>
            <th>Owning Time</th>
          </tr>
          <tr v-for="owner in ownerList">
            <td>{{ owner.pipeline.name }}</td>
            <td>{{ owner.user.name }}</td>
            <td @click="$alert(owner.remark)">{{ owner.add_time | formatTimestamp }}</td>
          </tr>
          <tr v-if="!ownerList.length">
            <td colspan="3">Nobody own this organisation yet.</td>
          </tr>
        </table>
      </el-form>
    </div>
  </b-modal>
  </div>
</template>

<script>
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@configs/apis";
import { fetcher, FetchMethod } from "@/libs/axios";
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import countries from "countries-list";
import { getCrmProfile } from "@/utils/account-localstorage";
import { isSuperAdmin } from "@/auth/crm";

export default {
  components: {
    AdvanceTable,
    vSelect,
    ToastificationContent
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "id", modelName: "id", label: "Org Code", filtertype: "input",stickyColumn: false, sortable: true, width: 100  },
        { key: "name", modelName: "name", label: "Name", filtertype: "input", stickyColumn: false,sortable: true },
        { key: "country_code", modelName: "countryCode", label: "Country", filtertype: "input", stickyColumn: false, sortable: true, width: 100 },
        { key: "address", modelName: "address", label: "Address", filtertype: "input", stickyColumn: false, sortable: true },
        { key: "status", modelName: "status", label: "Status", filtertype: "select", sortable: true, stickyColumn: false, options: { '': "All",  'DOCUMENTED': "Documented", 'VERIFIED': "Verified", 'PENDING': "Pending" }, width: 150 },
        { key: "owner", modelName: "owner", label: "Owner", searchable: false, stickyColumn: false, sortable: false },
      ],
      action: null,
      title:'Add Organisation',
      info:{
        nameWarning: null,
        status: 'PENDING'
      },
      targetId: null,
      sourceOrganization: {},
      targetOrganization: {},
      isShow:false,
      isShowOwner: false,
      isShowMerge: false,
      isShowKeyword: false,
      ignoredKeywords: "",
      statusList: [{id: "DOCUMENTED", name: 'Documented'}, {id: 'VERIFIED', name: 'Verified'}, {id: 'PENDING', name: 'Pending' }],
      organizationList: [],
      ownerList: [],
      sortBy: "",
      sortDesc: true,
      isLoading: false,
    };
  },
  computed: {
    countryList() {
        const countryCodes = Object.keys(countries.countries);
        const list = countryCodes.map(code => ({ value: code, text: countries.countries[code].name + " / " + countries.countries[code].native }));
        return list.sort((a, b) => a.text.localeCompare(b.text));
    },
    nameWarningText() {
      return this.info.nameWarning.replace(/\n/g, '<br>');
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadOrganizationList();
  },
  methods: {
    isSuperAdmin,
    getCrmProfile,
    clearData(){
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openModalForCreate() {
        Object.keys(this.info).forEach(key => (this.info[key] = ''));
        this.isShow = true
        this.action = 'CREATE';
        this.title = 'Add Organisation'
    },
    openModalForEdit(item) {
        this.info = item
        this.isShow = true
        this.action = 'UPDATE';
        this.title = 'Edit Organisation'
    },
    openModalForKeyword() {
      this.loadIgnoredKeywords();
      this.isShowKeyword = true;
    },
    ownerShow(row) {
      this.info = row.item;

      const response = fetcher(
        apis.crmGetOrganization + '/' + row.item.id + '/pipeline',
        FetchMethod.GET,
        {pageSize: 1000000}
      ).then(data => {
        this.ownerList = data.data;
      });

      this.isShowOwner = true
    },
    mergeOrganizationShow(row) {
      this.isShowMerge = true;
      this.info = row.item;

      this.checkName()

      this.sourceOrganization = row.item;
    },
    search(val){
        this.$refs.advanceTable.loadList({});
    },

    selectTargetOrganization(id) {
      this.targetOrganization = this.organizationList.find(organization => organization.id === id);
    },

    async loadOrganizationList() {
      try {
        this.isLoading = true;
        const response = await fetcher(
          apis.crmGetOrganization,
          FetchMethod.GET,
          {pageSize: 1000000}
        );
        this.organizationList = response.data;
      } catch (e) {
        console.info(e.message);
      } finally {
         this.isLoading = false;
      }
    },

    async verifyOrganization() {
      this.action = 'UPDATE';
      this.info.status = 'VERIFIED';
      await this.save();
      this.isShowMerge = false;
    },

    async mergeOrganization() {
      if (!this.sourceOrganization.id || !this.targetOrganization.id) {
        alert('Please select organization.')
        return false;
      }

      this.isLoading = true;
      const response = await fetcher(
        apis.crmMergeOrganization,
        FetchMethod.POST,
        {
          sourceOrganization: this.sourceOrganization,
          targetOrganization: this.targetOrganization,
        }
      )
        .then(res => {
          this.$refs.advanceTable.loadList();
          this.isShowMerge = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Organization merged success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {this.isLoading = false;})
    },

    async loadIgnoredKeywords() {
      const response = await fetcher(
        apis.crmGetMasterData + "/ORGANIZATION_KEYWORD/IGNORED",
        FetchMethod.GET
      ).then(response => {
        this.ignoredKeywords = response.value.join("\n");
      });
    },

    async saveIgnoredKeywords() {
      const response = await fetcher(
        apis.crmGetMasterData + "/ORGANIZATION_KEYWORD/IGNORED",
        FetchMethod.PUT,
        {value: this.ignoredKeywords.split("\n").filter(function(el) { return el; })}
      ).then(() => {
        this.isShowKeyword = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Keyword Saved',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      });
    },

    async checkName() {
      const response = await fetcher(
        apis.crmCheckOrganizationName,
        FetchMethod.POST,
        {
          "id": this.info.id,
          "name": this.info.name
        }
      );

      if (!response.isValid) {
        this.info.status = 'PENDING';
      } else {
        this.info.status = 'PENDING';
      }

      this.info.nameWarning = response.remark;
      this.info.remark = response.remark;
    },

    async save(){
        if (this.action === 'CREATE') {
            try {
                this.isLoading = true;
                const response = await fetcher(
                    apis.crmPostOrganization,
                    FetchMethod.POST,
                    {
                        "name": this.info.name,
                        "country_code": this.info.country_code,
                        "address": this.info.address
                    }
                );
                await this.$refs.advanceTable.loadList();
                await this.loadOrganizationList();
                this.isShow = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Create Organisation Success',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
            } catch (e) {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: e.detail,
                        icon: 'XIcon',
                        variant: 'danger',
                        },
                })
            } finally {
              this.isLoading = false;
            }
        }else{
            try {
                this.isLoading = true;
                const response = await fetcher(
                    apis.crmPutOrganization+'/'+this.info.id,
                    FetchMethod.PUT,
                    {
                        "name": this.info.name,
                        "country_code": this.info.country_code,
                        "address": this.info.address,
                        "status": this.info.status
                    }
                );
                await this.$refs.advanceTable.loadList();
                await this.loadOrganizationList();
                this.isShow = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Organisation Success',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
            } catch (e) {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: e.detail,
                        icon: 'XIcon',
                        variant: 'danger',
                        },
                })
            } finally {
              this.isLoading = false;
            }
        }
    },
  }
};
</script>
