<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="crm/product" :height="window.height - 310" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" v-loading="isLoading">
          <template #title>
              {{ $t("Products") }}
          </template>

          <template #cell(origin_countries)="row">
            {{ row.value.map(c => c.countryCode).join(', ') }}
          </template>

          <template #cell(destination_countries)="row">
            {{ row.value.map(c => c.countryCode).join(', ') }}
          </template>

          <template #cell(is_temporary)="row">
              <div v-if="row.value">
                Yes
              </div>
              <div v-else>
                No
              </div>
          </template>

          <template #cell(actions)="row">
            <div v-if="row.item.is_temporary">
              <el-link icon="el-icon-document-copy" @click="mergeProductShow(row)" v-if="getCrmProfile().role_id === 1">{{$t('Merge')}}</el-link>
            </div>
            <div v-if="!row.item.is_temporary && (getCrmProfile().role_id === 1 || getCrmProfile().role_id === 2)">
              <el-link icon="el-icon-edit" @click="editProductShow(row)">{{$t('Edit')}}</el-link>
            </div>
          </template>

          <template #button>
            <b-button variant="info" class="mr-1" @click="syncProducts('K_PARCEL')">
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span class="align-middle">{{ $t("Sync K-parcel") }}</span>
            </b-button>

            <b-button variant="info" class="mr-1" @click="syncProducts('K_ENGINE')">
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span class="align-middle">{{ $t("Sync K-engine") }}</span>
            </b-button>
          </template>
        </AdvanceTable>
    </section>

  <b-modal id="modal-merge-product" hide-footer size="xl" v-model="isMergeDialogShow" :title="$t('Merge Temporary Product')">
    <div class="card p-2" v-loading="isLoading">
      <el-form>
        <b-form-group>
          <template slot="label"> <i class="el-icon-minus"></i>{{$t('Temporary Product')}} * </template>
          <b-row>
            <b-col cols="2"><label>ID</label> <el-input v-model="mergeForm.temporaryProduct.id" disabled/></b-col>
            <b-col cols="2"><label>{{$t('Code')}}</label> <el-input v-model="mergeForm.temporaryProduct.code" disabled/></b-col>
            <b-col cols="2"><label>{{$t('Name')}}</label> <el-input v-model="mergeForm.temporaryProduct.name" disabled/></b-col>
            <b-col cols="2"><label>{{$t('Type')}}</label> <el-input v-model="mergeForm.temporaryProduct.product_type" disabled/></b-col>
            <b-col cols="2"><label>{{$t('Origin')}}</label> <el-input v-model="mergeForm.temporaryProduct.origin" disabled/></b-col>
            <b-col cols="2"><label>{{$t('Destination')}}</label> <el-input v-model="mergeForm.temporaryProduct.destination" disabled/></b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col cols="12" align="center"><feather-icon icon="ArrowDownIcon" size="36" class="text-muted"/></b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <template slot="label"> <i class="el-icon-plus"></i>{{$t('Permanent Product')}} * </template>
          <validation-provider #default="{ errors }" name="Permanent Product" rules="required">
            <el-select v-model="mergeForm.permanentProduct.id" placeholder="Please Select Product" style="width: 100%" @change="selectPermanentProduct">
              <el-option v-for="item in productList.filter(p => !p.is_temporary)" :key="item.id" :value="item.id" :label="'ID: ' + item.id + ' / Code: ' + item.code + ' / Type: ' + item.product_type + ' / Origin: ' + item.origin + ' / Destination: ' + item.destination "></el-option>
            </el-select>
            <b-row>
              <b-col cols="2"><label>ID</label> <el-input v-model="mergeForm.permanentProduct.id" disabled/></b-col>
              <b-col cols="2"><label>{{$t('Code')}}</label> <el-input v-model="mergeForm.permanentProduct.code" disabled/></b-col>
              <b-col cols="2"><label>{{$t('Name')}}</label> <el-input v-model="mergeForm.permanentProduct.name" disabled/></b-col>
              <b-col cols="2"><label>{{$t('Type')}}</label> <el-input v-model="mergeForm.permanentProduct.product_type" disabled/></b-col>
              <b-col cols="2"><label>{{$t('Origin')}}</label> <el-input v-model="mergeForm.permanentProduct.origin" disabled/></b-col>
              <b-col cols="2"><label>{{$t('Destination')}}</label> <el-input v-model="mergeForm.permanentProduct.destination" disabled/></b-col>
            </b-row>
          </validation-provider>
        </b-form-group>
      </el-form>
      <div>
        <el-button
          style="float: right; margin: 20px 0; width: 200px; background: #66b1ff; border: #66b1ff; margin-left: 20px;"
          type="primary" @click="mergeProduct()" icon="el-icon-check">{{$t('Merge Products')}}
        </el-button>
      </div>
    </div>
  </b-modal>

  <b-modal id="modal-update-product" hide-footer size="xl" v-model="isEditDialogShow" :title="$t('Update Product')">
    <div class="card p-2" v-loading="isLoading">
      <el-form>
        <b-form-group>
          <b-row>
            <b-col cols="3"><label>ID</label> <el-input v-model="editForm.id" disabled/></b-col>
            <b-col cols="3"><label>{{$t('Code')}}</label> <el-input v-model="editForm.code" disabled/></b-col>
            <b-col cols="3"><label>{{$t('Name')}}</label> <el-input v-model="editForm.name" disabled/></b-col>
            <b-col cols="3"><label>{{$t('Type')}}</label> <el-input v-model="editForm.product_type" disabled/></b-col>
            <b-col cols="2" v-if="false"><label>{{$t('Origin')}}</label> <el-input v-model="editForm.origin" disabled/></b-col>
            <b-col cols="2" v-if="false"><label>{{$t('Destination')}}</label> <el-input v-model="editForm.destination" disabled/></b-col>
          </b-row>
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group>
              <template slot="label">{{$t('Origins')}} <i class="el-icon-arrow-right"></i></template>

              <div>
                <el-input v-model="editForm.origin" disabled/>
              </div>

              <div v-for="(countries, continental) in countryGroups" :key="continental" v-if="false">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle="'accordion-' + continental" variant="light" style="text-align: left">{{ continental }}</b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-' + continental" accordion="my-accordion-1" role="tabpanel">
                    <b-card-body>
                      <b-form-checkbox @change="toggleSelectAll('origin_countries', continental)" v-model="checkAll['origin_countries'][continental]">Select ALL</b-form-checkbox>
                      <b-form-checkbox-group id="checkbox-group-1" name="checkbox-group-1" v-model="editForm.origin_countries">
                        <b-form-checkbox :value="{countryCode: country}" v-for="country in countries" :key="country">{{ countryMap[country] ? countryMap[country].name : '' }} - {{ country }}</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <template slot="label">{{$t('Destination')}} <i class="el-icon-arrow-right"></i></template>
              <div v-for="(countries, continental) in countryGroups" :key="continental">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle="'accordion-' + continental" variant="light" style="text-align: left">{{ continental }}</b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-' + continental" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <b-form-checkbox @change="toggleSelectAll('destination_countries', continental)" v-model="checkAll['destination_countries'][continental]">Select ALL</b-form-checkbox>
                      <b-form-checkbox-group id="checkbox-group-2" name="checkbox-group-2" v-model="editForm.destination_countries">
                        <b-form-checkbox :value="{countryCode: country}" v-for="country in countries" :key="country">{{ countryMap[country] ? countryMap[country].name : '' }} - {{ country }}</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </el-form>
      <div>
        <el-button
          style="float: right; margin: 20px 0; width: 200px; background: #66b1ff; border: #66b1ff; margin-left: 20px;"
          type="primary" @click="updateProduct()" icon="el-icon-check">{{$t('Update')}}</el-button>
      </div>
    </div>
  </b-modal>

  </div>
</template>

<script>
import {
  getCrmProfile,
} from "@/utils/account-localstorage";

import { fetcher, FetchMethod } from "@/libs/axios";
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@/configs/apis";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import countries from "countries-list";
export default {
  components: {
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      countryGroups: {
        'Asia': ['HK', 'CN', 'JP', 'MO', 'MN', 'KP', 'KR', 'TW', 'BN', 'KH', 'ID', 'LA', 'MY', 'MM', 'PH', 'SG', 'TH', 'VN', 'AF', 'BD', 'BT', 'IN', 'IR', 'MV', 'NP', 'PK', 'LK', 'KZ', 'KG', 'TJ', 'TM', 'UZ'],
        'Europe': ['BY', 'BG', 'CZ', 'HU', 'MD', 'PL', 'RO', 'RU', 'SK', 'UA', 'AT', 'BE', 'FR', 'GF', 'DE', 'LI', 'LU', 'MC', 'NL', 'CH', 'AL', 'AD', 'BA', 'HR', 'GI', 'GR', 'IT', 'XK', 'MT', 'ME', 'MK', 'PT', 'SM', 'RS', 'SI', 'ES', 'VA', 'DK', 'EE', 'FO', 'FI', 'GG', 'IS', 'IE', 'JE', 'LV', 'LT', 'NO', 'SE', 'GB'],
        'America': ['BM', 'BQ', 'CA', 'KY', 'GL', 'GP', 'BL', 'LC', 'XM', 'VC', 'TC', 'US', 'VG', 'VI', 'AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE', 'BZ', 'CR', 'SV', 'GT', 'HN', 'MX', 'NI', 'PA'],
        'Oceania': ['AS', 'AU', 'CK', 'FJ', 'GU', 'KI', 'MP', 'MH', 'FM', 'NR', 'NC', 'NZ', 'NU', 'PW', 'PG', 'WS', 'SB', 'PF', 'TO', 'TV', 'VU'],
        'Africa': ['DZ', 'AO', 'BJ', 'BW', 'BF', 'BI', 'CM', 'IC', 'CV', 'CF', 'TD', 'KM', 'CG', 'CD', 'CI', 'DJ', 'EG', 'ER', 'ET', 'SZ', 'GA', 'GM', 'GH', 'GN', 'GW', 'GQ', 'KE', 'LS', 'LR', 'LY', 'MG', 'MW', 'ML', 'MR', 'MU', 'YT', 'MA', 'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'SH', 'ST', 'SN', 'SC', 'SL', 'SO', 'XS', 'ZA', 'SS', 'SD', 'TZ', 'TG', 'TN', 'UG', 'ZM'],
        'MiddleEast': ['AM', 'AZ', 'BH', 'CY', 'GE', 'IQ', 'IL', 'JO', 'KW', 'LB', 'OM', 'QA', 'SA', 'SY', 'TR', 'AE', 'YE'],
        'Carribbean': ['AI', 'AG', 'AW', 'BS', 'BB', 'CU', 'CW', 'DM', 'DO', 'GD', 'HT', 'JM', 'MQ', 'MS', 'XN', 'PR', 'XE', 'KN', 'TT'],
        'Special': ['EU', 'GL', 'WW']
      },
      columns: [
        { key: "name", modelName: "name", label: "Product Name", width: "250", filtertype: "input", sortable: true },
        { key: "product_type", modelName: "productType", label: "Product Type", width: "150", filtertype: "input", sortable: true },
        { key: "code", modelName: "code", label: "Product Code", width: "250", filtertype: "input", sortable: true },
        { key: "description", modelName: "description", label: "Product Description", width: "250", filtertype: "input", sortable: true },
        // { key: "origin", modelName: "origin", label: "Origin", filtertype: "input", sortable: true },
        // { key: "destination", modelName: "destination", label: "Destination", filtertype: "input", sortable: true },
        // { key: "origin_countries", modelName: "origin_countries", label: "Origin", filtertype: "input", sortable: true, width: "120" },
        // { key: "destination_countries", modelName: "destination_countries", label: "Destination", filtertype: "input", sortable: true, width: "120" },
        { key: "origin_description", modelName: "originCountries.countryCode", label: "Origin", filtertype: "input", sortable: true },
        { key: "destination_description", modelName: "destinationCountries.countryCode", label: "Destination", filtertype: "input", sortable: true },
        { key: "is_temporary", modelName: "isTemporary", label: "Temporary", width: "80", filtertype: "select", sortable: true, options: { false: "No", true: "Yes" } },
        { key: "source", modelName: "source", label: "Source", width: "120", filtertype: "select", sortable: true, options: { 'CRM': "CRM", "K_ENGINE": "K_ENGINE", "K_PARCEL": "K_PARCEL" }  },
        { key: "actions", modelName: "actions", label: "Actions", sortable: false, width: "100" },

      ],
      productList: [],
      editForm: {
      },
      checkAll: {
        origin_countries: {},
        destination_countries: {}
      },
      mergeForm: {
        temporaryProduct: {},
        permanentProduct: {}
      },
      isEditDialogShow: false,
      isMergeDialogShow: false,
      isLoading: false,
    };
  },
  computed: {
    countryMap() {
      var countryMap = countries.countries;
      countryMap['XE'] = { name: 'St. Eustatius' };
      countryMap['EU'] = { name: 'Europe Union' };
      countryMap['GL'] = { name: 'Global' };
      countryMap['WW'] = { name: 'Worldwide' };

      return countryMap;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadProduct();
  },
  methods: {
    getCrmProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    editProductShow(row) {
      this.isEditDialogShow = true;
      this.editForm = row.item;
    },

    mergeProductShow(row) {
      this.isMergeDialogShow = true;
      this.mergeForm.temporaryProduct = row.item;
    },

    selectPermanentProduct(id) {
      this.mergeForm.permanentProduct = this.productList.find(product => product.id === id);
    },

    toggleSelectAll(type, continental) {
      this.countryGroups[continental].forEach(country => {
        let newItem = {
          countryCode: country
        };

        if (this.checkAll[type][continental]) {
          // Push if not exist
          if (this.editForm[type].filter(e => e.countryCode === newItem.countryCode).length <= 0) {
            this.editForm[type].push(newItem);
          }
        } else {
          // Remove if exist
          this.editForm[type] = this.editForm[type].filter(e => e.countryCode !== newItem.countryCode);
        }
      });
    },

    async loadProduct() {
      const response = await fetcher(
        apis.crmGetProducts,
        FetchMethod.GET
      ).catch(e => {})

      this.productList = response.data;
    },

    async mergeProduct() {
      if (!this.mergeForm.temporaryProduct.id || !this.mergeForm.permanentProduct.id) {
        alert('Please select product.')
        return false;
      }

      this.isLoading = true;
      const response = await fetcher(
        apis.crmMergeProducts,
        FetchMethod.POST,
        this.mergeForm
      )
        .then(res => {
          this.$refs.advanceTable.loadList();
          this.$bvModal.hide('modal-merge-product');

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Product merged success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {this.isLoading = false;})
    },

    async updateProduct() {
      this.isLoading = true;
      const response = await fetcher(
        apis.crmUpdateProduct + "/" + this.editForm.id,
        FetchMethod.PUT,
        this.editForm
      )
        .then(res => {
          this.$refs.advanceTable.loadList();
          this.$bvModal.hide('modal-update-product');

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Product updated success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {this.isLoading = false;})
    },

    async syncProducts(source) {
      this.isLoading = true;
      const response = await fetcher(
        apis.crmSyncProducts,
        FetchMethod.POST,
        {
          source: source
        },
        {timeout: 3000000}
      )
        .catch(e => {})
        .finally(() => {this.isLoading = false;})

      this.$refs.advanceTable.loadList();

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sync Success',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }
  }
};
</script>

<style scoped></style>
